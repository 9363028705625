//.main-section-container {
//  &:after{
//    background-image: -webkit-linear-gradient(top, #ccc, #777);
//    background-image: -moz-linear-gradient(top, #ccc, #777);
//    background-image: -o-linear-gradient(top, #ccc, #777);
//    background-image: -ms-linear-gradient(top, #ccc, #777);
//    background-image: linear-gradient(top, #ccc, #777);
//  }
//  height: 100vh;
//  //background-image:url('../public/assets/images/main_bg.jpeg');
//}

#oxinails{
  padding-top: 130px;
  padding-bottom: 180px;
  //background-color: #ffffff;
}

//@media (min-width: 1200px) {
//  header.masthead h1 {
//
//  }
//}
.oxinails-section{
  .title{
    font-size: 2.5rem;
    line-height: 3rem ;
    font-weight: 600;
  }

  h2{
    font-size: 1.2rem;
  }
  .text{
    font-size: 1rem;
    line-height: 1.55;
    font-weight: lighter;
  }

  .suppliers{
    display: flex;
    flex-wrap: wrap;
    background: #f9f9f9;
    padding-top: 11px;

    .sypplier{
      background: white;
      min-width: 100px;
      width: calc(100% / 6);
      flex-grow: 1;
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
      padding: 5px;
      align-content: center;
      align-items: center;
      img{
        max-width: 140px;
        max-height: 110px;
        //height: fit-content;
        //width: 100%;
      }
    }
  }

}
