//.main-section-container {
//  &:after{
//    background-image: -webkit-linear-gradient(top, #ccc, #777);
//    background-image: -moz-linear-gradient(top, #ccc, #777);
//    background-image: -o-linear-gradient(top, #ccc, #777);
//    background-image: -ms-linear-gradient(top, #ccc, #777);
//    background-image: linear-gradient(top, #ccc, #777);
//  }
//  height: 100vh;
//  //background-image:url('../public/assets/images/main_bg.jpeg');
//}

#polystone{
  padding-top: 180px;
  padding-bottom: 180px;
  //background-color: #ffffff;
  background-color: #fafafa;
}

//@media (min-width: 1200px) {
//  header.masthead h1 {
//
//  }
//}
.polystone-section{
  .bg{
    height: 400px;
    background: url(../../../../../src/assets/images/nails.png);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    border-radius: 15px;
  }
}
.title{
  font-size: 2.5rem;
  line-height: 3rem ;
  font-weight: 600;
}

.text{
  font-size: 1.4rem;
  line-height: 1.55;
  font-weight: lighter;
}
